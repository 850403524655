const AUTH = "/auth";
const LOGIN = `${AUTH}/login`;
const ADMIN = "/admin";
const PHASE = `${ADMIN}/phase`;
const REPORT = "/report";

const ROUTE_PATHS = {
  root: "/",
  /* region /auth */
  auth: AUTH,
  login: `${AUTH}/login`,
  loginTeam: `${LOGIN}/team`,
  resetPassword: `${AUTH}/reset-password`,
  reminder: `${AUTH}/reminder/:id`,
  welcome: `${AUTH}/welcome/:id`,
  /* endregion */
  /* region public */
  terms: "/terms",
  policy: "/policy",
  approvedScreen: "/approve-screen/:hash",
  /* region /report */
  /* TODO: report link
   *   looks like we can replace:
   *   - /report/partner/:id,
   *   - /report/phase/:id,
   *   - /report/seller/:id
   *   with /report/:type/:reportId
   * */
  report: `${REPORT}/:type/:reportId`,
  reportPartner: `${REPORT}/partner/:id`,
  reportPhase: `${REPORT}/phase/:id`,
  reportSeller: `${REPORT}/seller/:id`,
  /* endregion */
  /* endregion */
  /* region /admin */
  admin: ADMIN,
  changePassword: `${ADMIN}/change-password`,
  approvedBrands: `${ADMIN}/approved-brands/:id`,
  /* region /admin/phase */
  phaseNew: `${PHASE}/new`,
  phaseEdit: `${PHASE}/edit/:id`,
  phaseDeploy: `${PHASE}/deploy/:id`,
  /* TODO: check usage */
  phaseDeployBulk: `${PHASE}/deploy/:id/bulk`,
  /* endregion */
  media: `${ADMIN}/media`,
  templates: `${ADMIN}/templates`,
  template: `${ADMIN}/template/:id`,
  profile: `${ADMIN}/profile`,
  prospects: `${ADMIN}/prospects`,
  prospect: `${ADMIN}/prospect/:id`,
  categories: `${ADMIN}/categories`,
  teams: `${ADMIN}/teams`,
  team: `${ADMIN}/team/:id`,
  users: `${ADMIN}/users`,
  partners: `${ADMIN}/partners`,
  partnerEdit: `${ADMIN}/partner/edit/:id`,
  parentCompanies: `${ADMIN}/parent-companies`,
  brands: `${ADMIN}/brands`,
  sellers: `${ADMIN}/sellers`,
  /* endregion */
};

export default ROUTE_PATHS;
