import { lazy } from "react";
import { Outlet } from "react-router-dom";
import LoadingSuspense from "components/LoadingSuspense";
import ROLES from "constants/user";
import ROUTE_PATHS from "constants/routePaths";
import RouteByRole from "routing/RouteByRole";

const AdminPage = lazy(() => import("pages/admin"));
const Dashboard = lazy(() => import("pages/admin/dashboard"));
const Phase = lazy(() => import("pages/admin/phase"));
const Media = lazy(() => import("pages/admin/media"));
const Prospects = lazy(() => import("pages/admin/prospects"));
const Prospect = lazy(() => import("pages/admin/prospect"));
const Teams = lazy(() => import("pages/admin/teams"));
const Team = lazy(() => import("pages/admin/team"));
const Templates = lazy(() => import("pages/admin/templates"));
const ApprovedBrands = lazy(() => import("pages/admin/approvedBrands"));
const Template = lazy(() => import("pages/admin/template"));
const Profile = lazy(() => import("pages/admin/profile"));
const Users = lazy(() => import("pages/admin/users"));
const ParentCompanies = lazy(() => import("pages/admin/parentCompanies/ParentCompanies"));
const Brands = lazy(() => import("pages/admin/brands"));
const Categories = lazy(() => import("pages/admin/categories"));
const Sellers = lazy(() => import("pages/admin/sellers"));
const Partners = lazy(() => import("pages/admin/partners"));
const Partner = lazy(() => import("pages/admin/partner"));
const ChangePassword = lazy(() => import("pages/admin/changePassword"));
const Deploy = lazy(() => import("pages/admin/deploy"));
const BulkSending = lazy(() => import("pages/admin/bulkSending"));
const CreatePhase = lazy(() => import("pages/admin/createPhase"));
const NotFound = lazy(() => import("pages/notFound"));

const SUPER_ADMIN_ROLES = [ROLES.SUPER_ADMIN];
const ADMIN_ROLES = [ROLES.CLIENT_ADMIN, ROLES.SUPER_ADMIN];
const ACTIVE_ROLES = [...ADMIN_ROLES, ROLES.EXTERNAL];
const BULK_SENDING_ROLES = [ROLES.CLIENT_ADMIN, ROLES.EXTERNAL];
const PARENT_COMPANIES_ROLES = [ROLES.SUPER_ADMIN, ROLES.PROSPECTING];
const PROSPECTS_ROLES = [ROLES.SUPER_ADMIN, ROLES.PROSPECTING];
const DEPLOY_ROLES = [...ACTIVE_ROLES, ROLES.PROSPECTING];

const ADMIN_ROUTE_OBJECT = {
  path: ROUTE_PATHS.admin,
  element: (
    <AdminPage>
      <LoadingSuspense>
        <Outlet />
      </LoadingSuspense>
    </AdminPage>
  ),
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: ROUTE_PATHS.changePassword,
      element: <ChangePassword />,
    },
    {
      path: ROUTE_PATHS.approvedBrands,
      element: <ApprovedBrands />,
    },
    {
      element: (
        <RouteByRole roles={DEPLOY_ROLES}>
          <Outlet />
        </RouteByRole>
      ),
      children: [
        {
          path: ROUTE_PATHS.phaseEdit,
          element: <Phase />,
        },
        {
          path: ROUTE_PATHS.phaseDeploy,
          element: <Deploy />,
        },
      ],
    },
    {
      element: (
        <RouteByRole roles={ACTIVE_ROLES}>
          <Outlet />
        </RouteByRole>
      ),
      children: [
        {
          path: ROUTE_PATHS.phaseNew,
          element: <CreatePhase />,
        },
        {
          path: ROUTE_PATHS.media,
          element: <Media />,
        },
        {
          path: ROUTE_PATHS.templates,
          element: <Templates />,
        },
        {
          path: ROUTE_PATHS.template,
          element: <Template />,
        },
        {
          path: ROUTE_PATHS.profile,
          element: <Profile />,
        },
      ],
    },
    {
      element: (
        <RouteByRole roles={PROSPECTS_ROLES}>
          <Outlet />
        </RouteByRole>
      ),
      children: [
        {
          path: ROUTE_PATHS.prospects,
          element: <Prospects />,
        },
        {
          path: ROUTE_PATHS.prospect,
          element: <Prospect />,
        },
        {
          path: ROUTE_PATHS.categories,
          element: <Categories />,
        },
      ],
    },
    {
      element: (
        <RouteByRole roles={SUPER_ADMIN_ROLES}>
          <Outlet />
        </RouteByRole>
      ),
      children: [
        {
          path: ROUTE_PATHS.teams,
          element: <Teams />,
        },
        {
          path: ROUTE_PATHS.team,
          element: <Team />,
        },
        {
          path: ROUTE_PATHS.users,
          element: <Users />,
        },
        {
          path: ROUTE_PATHS.partners,
          element: <Partners />,
        },
        {
          path: ROUTE_PATHS.partnerEdit,
          element: <Partner />,
        },
        {
          path: ROUTE_PATHS.sellers,
          element: <Sellers />,
        },
      ],
    },
    {
      element: (
        <RouteByRole roles={PARENT_COMPANIES_ROLES}>
          <Outlet />
        </RouteByRole>
      ),
      children: [
        {
          path: ROUTE_PATHS.parentCompanies,
          element: <ParentCompanies />,
        },
        {
          path: ROUTE_PATHS.brands,
          element: <Brands />,
        },
      ],
    },
    {
      path: ROUTE_PATHS.phaseDeployBulk,
      element: (
        <RouteByRole roles={BULK_SENDING_ROLES}>
          <BulkSending />
        </RouteByRole>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default ADMIN_ROUTE_OBJECT;
