import { useMemo } from "react";
import { useMatch } from "react-router-dom";

/* TODO: TODO: report link
 *   replace with ROUTE_PATHS.report */
const REPORT_ID_PATTERN = "report/:type/:reportId";

export default function useGetReportParams() {
  const match = useMatch(REPORT_ID_PATTERN);
  const reportId = match?.params.reportId;
  const type = match?.params.type;

  return useMemo(() => ({ reportId, type }), [reportId, type]);
}
